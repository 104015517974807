<template>
    <div class="company c_content_container">
        <section class="c_inner">
            <h1 class="l_page_title">회사소개</h1>
            <div class="overview">
                <p>
                    <strong>안녕하세요, 주식회사 티온컴퍼니입니다!</strong>              
                    주식회사 티온컴퍼니는 온라인/오프라인 결제 분야 전문가 중심으로 구성된 인력을 바탕으로<br />
                    다양한 고객분들에게 기존 결제시스템의 불편함을 해소해드릴 뿐 아니라<br />
                    편리하고 낮은 비용으로 이용하실수 있도록 서비스를 제공하고 있습니다.
                </p>
                <p>보다 더 나은 결제 솔루션을 제공하는 티온페이는 고객님의 사업성공을 가장 가치에 둡니다.</p>
            </div>
            <ul class="company_strength">
                <li v-slideCombine>
                    <div class="text">
                        <h1>간편한 결제시스템 도입<br /><strong>단말기 없는 결제는 기본!</strong></h1>
                        <p>
                            카드 단말기가 있어야 결제가 된다구요?<br /> 
                            티온페이로 문의해보세요. 환경에 구애받지 않고 어디서나 결제할 수 있는<br /> 
                            환경을 제공합니다. 간단한 PG 가입만 되면 바로 결제가 가능합니다.<br /> 
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_01.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine.reverse>
                    <div class="text">
                        <h1>무료로 사용 가능한<br /><strong>관리자 사이트</strong></h1>
                        <p>
                            NO.1 무료통합결제 솔루션 앱과 더불어,<br />
                            PC / 휴대폰 관리자 링크를 함께 제공합니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_02.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine>
                    <div class="text">
                        <h1>기다릴 필요 없이 <br />모든 카드사 정산금<br /><strong>다음날 입금</strong></h1>
                        <p>
                            티온페이는 빠른 정산 결제 솔루션을 제공하여 기다릴 필요없이<br />
                            빠르게 결제 대금을 정산받으실 수 있습니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_03.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine.reverse>
                    <div class="text">
                        <h1><strong>링크 주소하나로<br /></strong>언제 어디서나 결제</h1>
                        <p>
                            원하는 결제 목적에 따라 1회 결제, 반복 결제가 가능한<br />
                            URL 링크 및 QR 코드 생성 기능을 제공합니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_04.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine>
                    <div class="text">
                        <h1>티온페이를 통한 서비스 가입 후 <br /><strong>즉시 결제 서비스 오픈</strong></h1>
                        <p>
                            합리적이고 빠른 도입을 통해 고객분들께<br />
                            최상의 서비스를 제공합니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_05.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine.reverse>
                    <div class="text">
                        <h1>여러 사람에게<br /><strong>원격결제 &amp; 동시결제</strong></h1>
                        <p>
                            대면 결제가 어려운 고객에게<br />
                            SMS로 전송하여 원격 결제를 하는 기능을 제공합니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_06.png" alt="회사강점" />
                    </div>
                </li>
                <li v-slideCombine>
                    <div class="text">
                        <h1><strong>오프라인 결제</strong><br /> 시스템</h1>
                        <p>                         
                            저렴한 기기 제공과 확실한 A/S 서비스를 통해 <br />
                            편리한 오프라인 결제 시스템을 제공합니다.
                        </p>
                    </div>
                    <div class="img_box">
                        <img src="~@/assets/image/company/company_strength_07.png" alt="회사강점" />
                    </div>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_company.css"></style>